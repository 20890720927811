import SmoothScroll from "smooth-scroll";

new SmoothScroll('a[href*="#"]', {
  speed: 900,
  speedAsDuration: true,
});

function appearLineButton() {
  const fixedContact = document.querySelector('.fixed-contact');
  const displayPoint = window.innerHeight / 2;
  const scrollPosition = window.scrollY;

  if(displayPoint <= scrollPosition) {
    fixedContact.style.zIndex = 9;
    if(fixedContact.style.zIndex == 9) {
      fixedContact.style.opacity = 1;
    }
  }
  if(scrollPosition <= displayPoint) {
    fixedContact.style.opacity = 0;
    if(fixedContact.style.opacity == 0) {
      fixedContact.style.zIndex = -9;
    }
  }
}

window.addEventListener('scroll', appearLineButton);